Dropzone.autoDiscover = false;

$.ajaxSetup({
    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
});

$(".uploadportalHercertificeringFile").each(function () {

    var currentElement = $(this);
    // Get upload path
    var uploadPath = $(this).data('path');
    var uploadHash = $(this).data('hash');
    var uploadFolder = $(this).data('folder');
    var uploadDocument = $(this).data('document');

    // Set options
    var dropzoneOptions = {
        url: uploadPath,
        maxFilesize: 5,
        addRemoveLinks: true,
        dictDefaultMessage: "uploaden",
        dictFileTooBig: "Bestand is te groot ({{filesize}}MiB). Max bestandgrootte: {{maxFilesize}}MB.",
        dictResponseError: 'Server not Configured',
        acceptedFiles: ".pdf,.doc,.docx,.png,.jpg,.gif,.bmp,.jpeg",
        params: {
            'hash': uploadHash,
            'folder': uploadFolder,
            'document': uploadDocument
        },
        init: function () {
            var self = this;
            // config
            self.options.addRemoveLinks = true;
            self.options.dictRemoveFile = "";
            self.options.dictCancelUpload = "";
            //New file added
            self.on("addedfile", function (file) {
                //console.log('new file added ', file);
                currentElement.find('.dz-image').click(function () {
                    var filename = uploadHash + '/' + uploadFolder + '/' + uploadDocument + '.' + getExtension(file.name);
                    newWindow = window.open('/member/hercertificeren/download/file?filename=' + filename);
                });

                currentElement.find('.dz-filename span').html(uploadDocument + '.' + getExtension(file.name));

            });
            // Send file starts
            self.on("sending", function (file) {
                //console.log('upload started', file);
                $('.meter').show();
            });

            // File upload Progress
            self.on("totaluploadprogress", function (progress) {
                //console.log("progress ", progress);
                $('.roller').width(progress + '%');
            });

            self.on("queuecomplete", function (progress) {
                $('.meter').delay(999).slideUp(999);
                update();
            });

            // On removing file
            self.on("removedfile", function (file) {
                //console.log(file.name);

                var filename = uploadHash + '/' + uploadFolder + '/' + uploadDocument + '.' + getExtension(file.name);

                $.ajax({
                    type: 'POST',
                    url: '/member/hercertificeren/delete/file',
                    data: "filename=" + filename
                });
                update();

            });
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    };

    // Initialize
    $(this).dropzone(dropzoneOptions);

});

function getExtension(filename) {
    return filename.split('.').pop();
}

function update() {
    fileCount = $('.uploadportalHercertificeringFile').length;
    uploadedCount = $('.uploadportalHercertificeringFile .dz-success-mark').length;

    console.log(fileCount);
    console.log(uploadedCount);

    $('#uploadportalSubmit').prop('disabled', fileCount != uploadedCount);
}

$('[id^="removeFile-"]').on('click', function() {
    var filename = $(this).data('filename');

    $.ajax({
        type: 'POST',
        url: '/member/hercertificeren/delete/file',
        data: "filename=" + filename,
        success: function () {
            location.reload();
        }
    });
})

update();
