$(function () {
    initMemberNav();
});

var initMemberNav = function () {
    if ($('.c-member--navigation').length > 0) {
        $('.c-member--navigation li a').not('.c-header--logout__button').click(function (event) {
            event.preventDefault();
            $('.c-member--navigation li').removeClass('is-active');
            $(this).parent().addClass('is-active');
            initMemberBody($(this).data('rel'));

        });
        $(".c-member--navigation  select").change(function () {
            initMemberBody($(this).find("option:selected").val());
        });
    }
}

var initMemberBody = function (section) {
    $("html, body").animate({scrollTop: $(".c-member--navigation").offset().top - $('.c-header').outerHeight() - 15}, 1000);
    $('.c-member--body').removeClass('is-visible');
    $('.c-member--body__' + section).addClass('is-visible');
}

var uploadedFiles = [];

$(".memberFile").each(function () {
    var currentElement = $(this);
    // Get upload path
    var url = $(this).data('path');
    var type = $(this).data('type');

    // Set options
    var dropzoneOptions = {
        url: url,
        maxFilesize: 5,
        addRemoveLinks: true,
        dictDefaultMessage: "Selecteer document",
        dictFileTooBig: "Bestand is te groot ({{filesize}}MiB). Max bestandgrootte: {{maxFilesize}}MB.",
        dictResponseError: 'Server not Configured',
        acceptedFiles: ".pdf,.doc,.docx,.png,.jpg,.gif,.bmp,.jpeg",
        params: {},
        success: function (data) {
            console.log(data);
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    };

    // Initialize
    $(this).dropzone(dropzoneOptions);

});